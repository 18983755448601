import { ReactNode } from 'react';

import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import FilterDramaTwoToneIcon from '@mui/icons-material/FilterDramaTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import CodeTwoToneIcon from '@mui/icons-material/CodeTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import RadarTwoToneIcon from '@mui/icons-material/RadarTwoTone';
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import MailLockTwoTone from '@mui/icons-material/MailLockTwoTone';
import { StorybookIcon } from 'src/content/pages/Storybook';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Home',
        link: '/',
        icon: HomeTwoToneIcon
      }
    ]
  },
  {
    heading: 'Information',
    items: [
      {
        name: 'Services',
        icon: FilterDramaTwoToneIcon,
        items: [
          {
            name: 'Mine',
            link: '/services/mine'
          },
          {
            name: 'By Team',
            link: '/services/byteam'
          },
          {
            name: 'By Platform',
            link: '/services/byplatform'
          },
          {
            name: 'All',
            link: '/services/all'
          }
        ]
      },
      {
        name: 'Teams',
        icon: PeopleAltTwoToneIcon,
        items: [
          {
            name: 'Mine',
            link: '/teams/mine'
          },
          {
            name: 'By Platform',
            link: '/teams/byplatform'
          },
          {
            name: 'All',
            link: '/teams/all'
          }
        ]
      },
      {
        name: 'Platforms',
        icon: DashboardTwoToneIcon,
        link: '/platforms'
      }
    ]
  },
  {
    heading: 'Resources',
    items: [
      {
        name: 'API Browser',
        icon: CodeTwoToneIcon,
        link: '/apibrowser'
      },
      {
        name: 'Application Standards',
        icon: AccountBalanceTwoToneIcon,
        link: 'https://sgico.sharepoint.com/sites/SGIPrincipals/SitePages/SGI-Practices-Standards.aspx'
      },
      {
        name: 'Arch Decisions Records',
        icon: FeedTwoToneIcon,
        link: 'https://dev.azure.com/SGICanDevOps/SGI%20Principles/_git/Architectural%20Decision%20Log'
      },
      {
        name: 'Developer Wiki',
        icon: ArticleTwoToneIcon,
        link: 'https://dev.azure.com/SGICanDevOps/DevOps%20Team/_wiki/wikis/DevOps%20Team/1294/README'
      },
      {
        name: 'Platform Roadmap',
        icon: MapTwoToneIcon,
        link: '/roadmap'
      },
      {
        name: 'Encrypted Notes',
        icon: MailLockTwoTone,
        link: '/encryptednotes'
      },
      {
        name: 'Gravity Storybook',
        icon: StorybookIcon,
        link: '/gravity'
      },
      {
        name: 'Tech Radar',
        icon: RadarTwoToneIcon,
        link: '/techradar'
      }
    ]
  },
  {
    heading: 'Help',
    items: [
      {
        name: 'Submit Request',
        icon: ContactSupportTwoToneIcon,
        link: '/submitrequest'
      },
      {
        name: 'Contact Us',
        icon: ChatBubbleTwoToneIcon,
        link: '/contactus'
      }
    ]
  }
];

export default menuItems;
