import { useContext, useEffect, useState } from 'react';
import { ReactComponent as StorybookIconSvg } from "./storybook-icon.svg";
import { HeaderContext } from 'src/contexts/HeaderContext';
import {  getStorybookVersions } from "src/api/storybookApi";
import { useSearchParams } from 'react-router-dom';

import { getAppConfig } from "src/appConfig";


export function StorybookIcon() {
  return(
    <StorybookIconSvg style={{ width: 27.719, height: 27.719 }} />
  )
}

function Storybook() {
  const storybookBlobUrl = new URL(getAppConfig().STORYBOOK_CONTAINER_URL);
  const { selectedDropDownItem, setDropDownValue, setDropDownItems } = useContext(HeaderContext);
  
  useEffect(() => {
    setDropDownValue("");
    setDropDownItems([ { name: "Loading...", value: "", data: null } ])

    getStorybookVersions().then(versions => {
      if (versions != null && versions.length > 0) {
        setDropDownItems(versions.map(version => ({ name: "v" + version.version, value: version.version, data: version.url })));
        setDropDownValue(versions[0].version);
      }
      else {
        setDropDownValue(null);
        setDropDownItems([]);
      }
    });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      setDropDownItems(null);
      setDropDownValue(null);
    };
    // eslint-disable-next-line
  }, []);

const [searchParams, setSearchParams] = useSearchParams();
const [storyPath, setStoryPath] = useState("");

useEffect(() => {
  setStoryPath(searchParams.get("path"));

  const messageHandler = event => {
    if (new URL(event.origin).host === storybookBlobUrl.host) {
      const data = JSON.parse(event.data);
      if (data.key === "storybook" && data.event.type === "story-changed") {
        setSearchParams(`path=${ data.event.storyId }`);
      }    
    }
  }
  window.addEventListener("message", messageHandler)
  return () => {
    window.removeEventListener("message", messageHandler);
  }
  // eslint-disable-next-line
}, [])

  const storybookBaseUrl = selectedDropDownItem ? selectedDropDownItem.data : "";
  const storybookUrl = storyPath ? `${storybookBaseUrl}?path=/story/${storyPath}` : storybookBaseUrl;
  return(
    <>
      <iframe title="storybook" 
        style={{height: '100%', width:'100%', border: 0 }}
        src={storybookUrl}
        allow="clipboard-read; clipboard-write" />
    </>
  )
}

export default Storybook;