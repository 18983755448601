import { Fade } from '@mui/material';

import './LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <ul className="loader" id="loadingImage" title="Loading...">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  );
};

export const CenteredLoadingSpinner = () => 
(
  <Fade in={true} timeout={500}>
    <div style={{ backgroundColor:'#f2f5f9', display:'flex', alignItems: 'center', flexGrow: 1 }}><LoadingSpinner/></div>
  </Fade>
) 