import { FC, useState, createContext, useEffect } from 'react';

import type { DropDownItem } from "src/layouts/SidebarLayout/Header";

type HeaderContext = { dropDownValue: string, selectedDropDownItem: any, dropDownItems: DropDownItem[], setDropDownValue: (dropDownValue: string) => void, setDropDownItems: (dropDownItems: DropDownItem[]) => void };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HeaderContext = createContext<HeaderContext>(
  {} as HeaderContext
);

export const HeaderProvider: FC = ({ children }) => {
  const [dropDownValue, setDropDownValue] = useState<string>(null);
  const [dropDownItems, setDropDownItems] = useState<DropDownItem[]>(null);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState<any>(null);

  useEffect(() => {
    setSelectedDropDownItem(dropDownItems != null ? dropDownItems.find(item => item.value === dropDownValue) : null);
  }, [dropDownValue, dropDownItems])

  return (
    <HeaderContext.Provider value={{ dropDownValue, selectedDropDownItem, dropDownItems, setDropDownValue, setDropDownItems }}>
      {children}
    </HeaderContext.Provider>
  );
};
