import { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import GetRoutes from './router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import ThemeProvider from './theme/ThemeProvider';
import { Box, CssBaseline } from '@mui/material';

import WebFont from 'webfontloader';
import { CenteredLoadingSpinner }  from './common/loadingSpinner/LoadingSpinner';

const Application = () => {
  return(
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <AppContent/>
      </LocalizationProvider> 
    </ThemeProvider>
  );
}

const AppContent = () => 
{
  const [ fontsLoaded, setFontsLoaded ] = useState(false);
  const content = useRoutes(GetRoutes());

  const webFontConfig = {
    google: {
      families: ['Inter'],
    },
    classes: false,
    timeout: 2000,
    active: () => setFontsLoaded(true)
  };

  WebFont.load(webFontConfig);

  return fontsLoaded ? content : (<Box style={{ display: "flex", height: "100%" }}><CenteredLoadingSpinner /></Box>)
}

export default Application;