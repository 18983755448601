import { FC, useState, useEffect, useMemo, createContext } from 'react';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SidebarMode } from 'src/layouts/SidebarLayout';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

export enum SidebarState {
  Standard = 0,
  Minimal = 1,
  Mobile = 2,
  Unauthenticated = 3
}

type SidebarContext = { 
  isSidebarOpen: boolean
  sidebarState: SidebarState
  showLogin: boolean
  showHeader: boolean
  allowAnonymous: boolean
  toggleSidebar: () => void
  setIsSidebarOpen: (newState: boolean) => void
  setSidebarMode: (newMode: SidebarMode) => void
  setAllowAnonymous: (newValue: boolean) => void
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const isAuthenticated = useIsAuthenticated();
  const instance = useMsal();

  const [sidebarMode, setSidebarMode] = useState(SidebarMode.Standard);
  const [allowAnonymous, setAllowAnonymous] = useState(false);
  const [targetIsOpen, setTargetIsOpen] = useState(false);

  const showLogin = useMemo(() => !isAuthenticated && !allowAnonymous, [isAuthenticated, allowAnonymous])
  const showHeader = useMemo(() => isAuthenticated || allowAnonymous, [isAuthenticated, allowAnonymous])

  const sidebarState = useMemo(() => 
  {
    if (!isAuthenticated && instance.inProgress === InteractionStatus.None) {
      return SidebarState.Unauthenticated;
    }
    
    if (!isLarge) {
      return SidebarState.Mobile;
    }

    if (sidebarMode !== SidebarMode.Standard) {
      return SidebarState.Minimal;
    } 
    
    return SidebarState.Standard;
  }, [isAuthenticated, instance.inProgress, isLarge, sidebarMode]);
  
  const isSidebarOpen = useMemo(() => sidebarState === SidebarState.Standard || targetIsOpen, [targetIsOpen, sidebarState]);

  useEffect(() => {
    if (sidebarMode === SidebarMode.Standard && sidebarState === SidebarState.Mobile)
      setTargetIsOpen(false);
    }, 
    [sidebarMode, sidebarState]
  );

  const toggleSidebar = () => {
    setTargetIsOpen(!isSidebarOpen);
  };

  const contextValue = { 
    isSidebarOpen: isSidebarOpen, 
    sidebarState: sidebarState, 
    showLogin: showLogin,
    showHeader: showHeader,
    allowAnonymous: allowAnonymous,
    toggleSidebar: toggleSidebar, 
    setIsSidebarOpen: setTargetIsOpen, 
    setSidebarMode: setSidebarMode, 
    setAllowAnonymous: setAllowAnonymous 
  }

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
};
