import {
    Button,
    Grid,
    Typography
  } from '@mui/material';
  
import { styled } from '@mui/material/styles';

import { loginRequest } from 'src/auth/authConfig';

import { useMsal } from '@azure/msal-react';

  const TypographyH1 = styled(Typography)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(50)};
      text-align: center;
  `
  );
  
  const TypographyH2 = styled(Typography)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(17)};
  `
  );
  
  function LoginBox() {
    const { instance } = useMsal();

    return (
      <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
        >
        <TypographyH1 sx={{ mb: 2 }} variant="h1">
            SGI DevOps Portal
        </TypographyH1>
        <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
        >
            Log in to your SGI AD account for access
        </TypographyH2>
        <Button
            size="large"
            variant="contained"
            onClick={() => {
                instance.loginRedirect(loginRequest).catch((error) => {
                  console.log(error);
                });
              }}
        >
            Login
        </Button>
      </Grid>
    );
  }
  
  export default LoginBox;
  