import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { MsalProvider } from '@azure/msal-react';
import { initializeMsalInstance } from './auth/initializeMsal';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { DragAndDropProvider } from './contexts/DragAndDropContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { HeaderProvider } from './contexts/HeaderContext';

import 'nprogress/nprogress.css';

const RouterWrapper = () => 
  <BrowserRouter>
    <MsalWrappedApp/>    
  </BrowserRouter>

const EmptyApp = () => <></>

const siteInsideMsalIframe = (window !== window.parent && !window.opener);

const MsalWrappedApp = () =>
{
  // This block of code was added on 2023-09-28 to fix an issue with the switch to B2B caused by lingering local storage from B2C logins
  // This can be removed after any time after 2023-11-01
  // ------------ START ------------ //
  const containsB2c = new RegExp("^.*b2c_1a_.*$");

  for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i)

    if (containsB2c.test(key)) {
      localStorage.clear()
      sessionStorage.clear()
      
      window.location.reload()

      break
    }
  }
  // ------------  END  ------------ //

  const msalInstance = initializeMsalInstance();
  
  return(
    <MsalProvider instance={msalInstance}>         
      {siteInsideMsalIframe ? <EmptyApp /> : <AppProvider /> } 
    </MsalProvider>      
  )
}

const AppProvider = () =>
{
 
  return(
    <DragAndDropProvider>
      <HelmetProvider>
        <SidebarProvider>
          <HeaderProvider>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </HeaderProvider>
        </SidebarProvider>
      </HelmetProvider>
    </DragAndDropProvider>
  )
}

ReactDOM.render(<RouterWrapper/>, document.getElementById('root'));