import { Helmet } from 'react-helmet-async';

import {
    Box,
    Typography,
    Container,
    Grid
  } from '@mui/material';
  
import { styled } from '@mui/material/styles';

const TypographyH1 = styled(Typography)(
    ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`
);

const TypographyH2 = styled(Typography)(
    ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

const MainContent = styled(Box)(
    () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
  );

function Home() {
  return (
    <>
      <Helmet>
        <title>DevOps Portal - Home</title>
      </Helmet>
      <MainContent>
        <Grid
          container
          alignItems="stretch"
          spacing={0}
        >
          <Container maxWidth="sm">
            <Box textAlign="center">
              <TypographyH1 sx={{ mb: 2 }} variant="h1">
                Home
              </TypographyH1>
              <TypographyH2
                sx={{ lineHeight: 1.5, pb: 4 }}
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
              >
                A landing page for relevant content.
              </TypographyH2>
            </Box>
          </Container>
        </Grid>
      </MainContent>
    </>
  );
}

export default Home;
