import { FC, useState, createContext, useEffect, useRef, useMemo } from 'react';

type DragAndDropContext = { isDragOverWindow, setDragEnter: () => void, setDragLeave: () => void };

const useEventListener = <T extends Event,>(type : string, handler : (event : T) => void) => {
    const savedHandler = useRef<(event : T) => void>();
  
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);
  
    useEffect(() => {
      const listener = (event : T) => savedHandler.current(event);
  
      window.addEventListener(type, listener);
  
      return () => {
        window.removeEventListener(type, listener);
      };
    }, [type]);
  };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DragAndDropContext = createContext<DragAndDropContext>(
  {} as DragAndDropContext
);

export const DragAndDropProvider: FC = ({ children }) => {
  const dragCounter = useRef(0);
  const [isDragActive, setIsDragActive] = useState(false);

  const windowDragCounter = useRef(0);
  const [isWindowDragActive, setIsWindowDragActive] = useState(false);
  
  const contextValue = useMemo<DragAndDropContext>(() => ({ 
    isDragOverWindow: isDragActive || isWindowDragActive, 
    setDragEnter: () => { dragCounter.current += 1; setIsDragActive(dragCounter.current > 0); }, 
    setDragLeave: () => { dragCounter.current -= 1; setIsDragActive(dragCounter.current > 0); } 
  }), [dragCounter, isDragActive, setIsDragActive, isWindowDragActive])

  const updateDropEffect = e => { 
    e.dataTransfer.dropEffect = dragCounter.current > 0 ? "copy" : "none"; 
    e.preventDefault();
  };

  window.addEventListener('dragover', (ev : DragEvent) => true);

  useEventListener<DragEvent>('drop', e => { windowDragCounter.current = 0; setIsWindowDragActive(false); });
  useEventListener<DragEvent>('dragenter', e => { windowDragCounter.current += 1; setIsWindowDragActive(windowDragCounter.current > 0); updateDropEffect(e); });
  useEventListener<DragEvent>('dragleave', e => { windowDragCounter.current -= 1; setIsWindowDragActive(windowDragCounter.current > 0); updateDropEffect(e); });
  useEventListener<DragEvent>('dragover', e => updateDropEffect(e));

  return (
    <DragAndDropContext.Provider value={contextValue}>
      {children}
    </DragAndDropContext.Provider>
  );
};
