import { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext, SidebarState } from 'src/contexts/SidebarContext';
import Logo from 'src/components/Logo';

import { Box, Drawer } from '@mui/material';

import { styled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 88px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);
function Sidebar() {
  const { isSidebarOpen, setIsSidebarOpen, sidebarState } = useContext(SidebarContext);
  const closeSidebar = () => setIsSidebarOpen(false);

  return (
    <Drawer
      anchor="left"
      open={isSidebarOpen}
      onClose={closeSidebar}
      variant="temporary"
      hideBackdrop={sidebarState === SidebarState.Standard}
      elevation={9}
      ModalProps={{
        keepMounted: true,
        disableEnforceFocus: sidebarState === SidebarState.Standard
      }}
      sx={{
        right: "auto"
      }}
      >
      <SidebarWrapper>
        <Scrollbars autoHide>
          <TopSection>
            <Logo />
          </TopSection>
          <SidebarMenu />
        </Scrollbars>
      </SidebarWrapper>
    </Drawer>
  );
}

export default Sidebar;
