import { FC, ReactNode, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { AuthenticatedTemplate } from '@azure/msal-react';
import { SidebarContext } from 'src/contexts/SidebarContext';

import Sidebar from './Sidebar';
import Header from './Header';

export enum SidebarMode {
  Standard = 0,
  Minimal = 1
}

interface SidebarLayoutProps {
  children?: ReactNode
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        overflow: auto;
        line-height: 0;
`
);

export const SidebarLayout: FC<SidebarLayoutProps> = (props) => {
  const theme = useTheme();
  const { showHeader } = useContext(SidebarContext);

  return (
    <>
      <AuthenticatedTemplate>
        <Sidebar />
      </AuthenticatedTemplate>
      <MainWrapper>
        <Header />
        <MainContent style={{ marginTop: showHeader ? theme.header.height : 0 }}>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};
