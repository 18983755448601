import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { SidebarLayout, SidebarMode } from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import { SidebarLayoutContent } from 'src/layouts/SidebarLayout/SidebarLayoutContent';

// Pages

import Home from 'src/content/pages/Home';
const ApiBrowser = lazy(() => import('src/content/pages/ApiBrowser'));
const RedocView = lazy(() => import('src/content/pages/RedocView'));
const Roadmap = lazy(() => import('src/content/pages/Roadmap'));
const ContactUs = lazy(() => import('src/content/pages/ContactUs'));
const Storybook = lazy(() => import('src/content/pages/Storybook'));
const TechRadar = lazy(() => import('src/content/pages/TechRadar'));
const EncryptedNotes = lazy(() => import('src/content/pages/EncryptedNotes'));
const EncryptedNoteDetails = lazy(() => import('src/content/pages/EncryptedNoteDetails'));
const ViewEncryptedNote = lazy(() => import('src/content/pages/ViewEncryptedNote'));
const DecryptedNote = lazy(() => import('src/content/pages/DecryptedNote'));

// Status

const Status404 = lazy(() => import('src/content/pages/Status/Status404'));
const Status500 = lazy(() => import('src/content/pages/Status/Status500'));
const StatusComingSoon = lazy(() => import('src/content/pages/Status/ComingSoon'));
const StatusMaintenance = lazy(() => import('src/content/pages/Status/Maintenance'));

const Services = lazy(() => import('src/content/pages/Services'));
const Teams = lazy(() => import('src/content/pages/Teams'));
const AppRegistrationForm = lazy(() => import('src/content/pages/Components/AppRegistrationForm'));

enum Layout {
  Base,
  Sidebar
}

interface RouteConfig {
  path: string,
  layout: Layout,
  children?: { path: string, element: JSX.Element, sidebarMode?: SidebarMode, allowAnonymous?: boolean }[]
}

const GetRoutes = () => {
  const processRouteObject = (route: RouteConfig) => (
    {
      path: route.path,
      element: route.layout === Layout.Sidebar ? (<SidebarLayout /> ) : (<BaseLayout />),
      children: route
        .children
        ?.map(child => 
          indentRouteObject(
            child.path, 
            child.element, 
            child.sidebarMode ?? (route.layout === Layout.Sidebar ? SidebarMode.Standard : null),
            child.allowAnonymous ?? false
          )
        )
    }
  );

  const indentRouteObject = (path: string, element: JSX.Element, sidebarMode: SidebarMode, allowAnonymous: boolean) => (
    {
      path: path,
      element: (
        <SidebarLayoutContent mode={sidebarMode ?? SidebarMode.Standard} allowAnonymous={allowAnonymous}>
          {element}
        </SidebarLayoutContent>
      )
    }
  );
    
  return [
    {
      path: '',
      layout: Layout.Sidebar,
      children: [
        {
          path: '',
          element: <Home />
        },
        {
          path: 'platforms',
          element: <StatusComingSoon />
        },
        {
          path: 'apibrowser',
          element: <ApiBrowser />
        },
        {
          path: 'roadmap',
          element: <Roadmap />
        },
        {
          path: 'encryptednotes',
          allowAnonymous: true,
          element: <EncryptedNotes />
        },
        {
          path: 'encryptednotes/details',
          allowAnonymous: true,
          element: <EncryptedNoteDetails />
        },
        {
          path: 'encryptednotes/:schemaVersion/:noteId',
          allowAnonymous: true,
          element: <ViewEncryptedNote />
        },
        {
          path: 'encryptednotes/:schemaVersion/:noteId/decrypted',
          allowAnonymous: true,
          element: <DecryptedNote />
        },
        {
          path: 'submitrequest',
          element: <AppRegistrationForm />
        },
        {
          path: 'contactus',
          element: <ContactUs />
        },
        {
          path: 'gravity',
          sidebarMode: SidebarMode.Minimal,
          element: <Storybook />
        },
        {
          path: 'techradar',
          sidebarMode: SidebarMode.Minimal,
          element: <TechRadar />
        },
        {
          path: 'apibrowser/:repositoryName',
          sidebarMode: SidebarMode.Minimal,
          element: <RedocView />
        },
        {
          path: 'services',
          element: <Navigate to="/services/mine" replace />
        },
        {
          path: 'services/mine',
          element: <StatusComingSoon />
        },
        {
          path: 'services/byteam',
          element: <StatusComingSoon />
        },
        {
          path: 'services/byplatform',
          element: <StatusComingSoon />
        },
        {
          path: 'services/all',
          element: <Services />
        },
        {
          path: 'teams',
          element: <Navigate to="/teams/mine" replace />
        },
        {
          path: 'teams/mine',
          element: <Teams />
        },
        {
          path: 'teams/byplatform',
          element: <StatusComingSoon />
        },
        {
          path: 'teams/all',
          element: <StatusComingSoon />
        }
      ]
    },
    {
      path: 'status',
      layout: Layout.Base,
      children: [
        {
          path: '', 
          element: <Status404 />
        },
        {
          path: '404',
          element: <Status404 />
        },
        {
          path: '500',
          element: <Status500 />
        },
        {
          path: 'maintenance',
          element: <StatusMaintenance />
        },
      ]
    },
    {
      path: '',
      layout: Layout.Base,
      children: [
        {
          path: '*', 
          element: <Status404 />
        }
      ]
    }
  ]
  .map(processRouteObject);
}

export default GetRoutes;
