import axios from 'axios';

import { XMLParser } from "fast-xml-parser";

import { getAppConfig } from "src/appConfig";

export type StorybookVersion = {
  version: string,
  url: string
};

export async function getStorybookVersions(): Promise<StorybookVersion[]> {
  const baseUrl = getAppConfig().STORYBOOK_CONTAINER_URL;

  const fullUrl = baseUrl + '?restype=container&comp=list&prefix=versions/&include=metadata';

  const response = await axios.get(fullUrl).then(res => res.data).catch(error => console.log(error));
  
  const parser = new XMLParser();

  let obj = parser.parse(response);

  let versions = obj.EnumerationResults.Blobs.Blob.map(blob => ({ version: blob.Name.replace("versions/", ""), url: baseUrl + "/" + blob.Metadata.path }));

  return versions.sort(compareVersions).reverse();
}

function compareVersions(versionOne: StorybookVersion, versionTwo: StorybookVersion) {
  if (versionOne === versionTwo) {
    return 0;
  }

  let splitOne = versionOne.version.split('.');
  let splitTwo = versionTwo.version.split('.');

  const length = Math.max(splitOne.length, splitTwo.length);
  for (let i = 0; i < length; i++) {
    if (parseInt(splitOne[i]) > parseInt(splitTwo[i]) || ((splitOne[i] === splitTwo[i]) && isNaN(parseInt(splitTwo[i + 1])))) {
      return 1;
    }
    if (parseInt(splitOne[i]) < parseInt(splitTwo[i]) || ((splitOne[i] === splitTwo[i]) && isNaN(parseInt(splitOne[i + 1])))) {
      return -1;
    }
  }
}