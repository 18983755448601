export interface AppConfig {
    REACT_APP_MSAL_CLIENT_ID: string;
    REACT_APP_MSAL_TENANT_ID: string;
    REACT_APP_MSAL_AAD_AUTHORITY: string;
    REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: string;
    REACT_APP_MSAL_FORGOTPASSWORD_POLICY: string;
    REACT_APP_MSAL_EDITPROFILE_POLICY: string;
    REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: string;    
    
    REACT_APP_BFF_API_BASEURL: string;
    REACT_APP_BFF_API_SCOPES: string;
    SGI_TENANT_ID: string;
    
    STORYBOOK_CONTAINER_URL: string;
    OPENID_CONFIGURATION: string;
  }
  
  export function getAppConfig(): AppConfig {
    return (window as any)["runConfig"] as AppConfig;
  }
  