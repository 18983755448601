import { FC, ReactNode, Suspense, useContext, useEffect } from 'react';
import { SidebarMode } from 'src/layouts/SidebarLayout';

import { SidebarContext, SidebarState } from 'src/contexts/SidebarContext';

import { Box, useTheme } from '@mui/material';

import LoginBox from 'src/components/LoginBox'
import { AuthenticatedTemplate, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';

import { InteractionType, InteractionStatus } from '@azure/msal-browser';
import { getScopes } from '../../../auth/authConfig';
import { CenteredLoadingSpinner }  from '../../../common/loadingSpinner/LoadingSpinner';

interface SidebarLayoutContentProps {
  children?: ReactNode;
  mode: SidebarMode;
  allowAnonymous: boolean;
}

export const SidebarLayoutContent: FC<SidebarLayoutContentProps> = (props) => {
  const theme = useTheme();
  const { sidebarState, setSidebarMode, setAllowAnonymous, showLogin } = useContext(SidebarContext);
  const scopes = getScopes();
  const instance = useMsal();
  
  useEffect(() => {
    setSidebarMode(props.mode);
  }, [props.mode, setSidebarMode]);

  useEffect(() => {
    setAllowAnonymous(props.allowAnonymous);
  }, [props.allowAnonymous, setAllowAnonymous]);
  
  const getElement = () => !showLogin
    ? props.allowAnonymous ?
      props.children
      :
      (
        <AuthenticatedTemplate>
          <MsalAuthenticationTemplate 
            loadingComponent={CenteredLoadingSpinner}
            interactionType={InteractionType.Redirect}
            errorComponent={({error})=> <>{console.log('ERROR: ', error?.errorMessage)}</>}
            authenticationRequest={{ scopes: scopes }}
          >
            {props.children}
          </MsalAuthenticationTemplate>
        </AuthenticatedTemplate>
      )
    : (<LoginBox />)

  return (
    <Box 
      style={{ 
        height: '100%', 
        display: "flex",
        flexDirection: "column", 
        paddingLeft: (instance.inProgress === InteractionStatus.None || instance.inProgress === InteractionStatus.SsoSilent) 
          && props.mode === SidebarMode.Standard 
          && sidebarState !== SidebarState.Mobile 
          && sidebarState !== SidebarState.Unauthenticated 
          ? theme.sidebar.width 
          : 0 
      }}>
      { instance.inProgress !== InteractionStatus.None && instance.inProgress !== InteractionStatus.SsoSilent ?
        (<CenteredLoadingSpinner />)
        :
        (
          <Suspense fallback={(<CenteredLoadingSpinner />)}>
            {getElement()}
          </Suspense>
        )
      }
      
    </Box>
  )
};
  