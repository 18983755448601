import { useContext } from 'react';

import { Button, Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext, SidebarState } from 'src/contexts/SidebarContext';
import { HeaderContext } from 'src/contexts/HeaderContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';

import { loginRequest } from 'src/auth/authConfig';

import HeaderMenu from './Menu';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import Logo from 'src/components/Logo';

export type DropDownItem = {
  name: string,
  value: string,
  data: any
};

const HeaderStandard = styled(Box)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 5;
    background-color: ${theme.header.background};
    box-shadow: ${theme.header.boxShadow};
    position: fixed;
    justify-content: space-between;
    width: 100%;
`
);

function Header() {
  const { instance } = useMsal();

  const { isSidebarOpen, toggleSidebar, sidebarState, showHeader } = useContext(SidebarContext);
  const { dropDownValue, dropDownItems, setDropDownValue } = useContext(HeaderContext);

  const handleChange = (event: SelectChangeEvent) => {
    setDropDownValue(event.target.value);
  };

  const login = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
      console.log(error);
    });
  }

  const DropDown = () => (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={dropDownValue}
        style={{ height: "40px" }}
        onChange={handleChange}
        disabled={dropDownItems != null && dropDownItems.length === 0}
      >
        {dropDownItems != null ? dropDownItems.map(item => <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>) : null}
      </Select>
    </FormControl>
  );

  const toggleButton = () => (
    <Tooltip arrow title="Toggle Menu" style={{ marginRight: 8 }}>
      <IconButton color="primary" onClick={toggleSidebar}>
        {!isSidebarOpen ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
      </IconButton>
    </Tooltip>
  );

  return showHeader ?
    (
      <HeaderStandard display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <AuthenticatedTemplate>
            {sidebarState === SidebarState.Minimal && toggleButton()}
          </AuthenticatedTemplate>
          <Logo />
          <HeaderMenu />
          {dropDownItems != null ? DropDown() : (<div></div>)}
        </Box>
        <Box display="flex" alignItems="center">
          <AuthenticatedTemplate>
            <HeaderButtons />
            <HeaderUserbox />
            {sidebarState === SidebarState.Mobile && toggleButton()}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
          <Button size="large" variant="outlined" onClick={login}>
              Login
          </Button>
          </UnauthenticatedTemplate>
        </Box>
      </HeaderStandard>
    )
    :
    <></>
    ;
}

export default Header;
